import { FormFieldCondition } from "@ludens-reklame/rubics-v3-sdk/form/types";

export function showFormField(
  conditions: FormFieldCondition[],
  data: Record<any, any>
): boolean {
  return conditions.every((c) => {
    let value = data[c.comparatorKey] || "";
    let targetValue = c.comparatorValue || "";

    if (Array.isArray(value) && c.operator === "set") {
      return value.length > 0;
    }

    if (typeof value !== "string" || typeof targetValue !== "string") {
      return false;
    }

    targetValue = targetValue.trim().toLowerCase();
    value = value.trim().toLowerCase();

    switch (c.operator) {
      case "set":
        return !!value;
      case "gt":
        return parseFloat(value) > parseFloat(targetValue);
      case "lt":
        return parseFloat(value) < parseFloat(targetValue);
      case "neq":
        return value !== targetValue;
      default:
        return value === targetValue;
    }
  });
}
