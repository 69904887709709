import { useMemo } from "react";
import {
  FormField as IFormField,
  Form as IForm,
} from "@ludens-reklame/rubics-v3-sdk/form/types";
import { useForm } from "@ludens-reklame/rubics-v3-react/form/hooks";
import { UseInputInterface } from "@ludens-reklame/rubics-v3-react/util/hooks";
import { useStore } from "@ludens-reklame/rubics-v3-react/store/hooks";
import { showFormField } from "../../client-utils/showFormField.js";
import { RichText } from "../richText/RichText.js";
import { arrow } from "../../icons/svg.js";

interface Props {
  form: IForm;
}

export const Form: React.FC<Props> = ({ form }) => {
  const store = useStore();
  const _form = useForm(form);

  const visibleFields = useMemo<IFormField[]>(() => {
    return form.fields.filter((f) => {
      if (f.type === "hidden") {
        return false;
      }

      if (Array.isArray(f.conditions) && f.conditions.length > 0) {
        return showFormField(f.conditions, _form.input);
      }

      return true;
    });
  }, [form, _form.input]);

  if (_form.success) {
    return (
      <div className="form-success">
        {form.successText ? (
          <div className="success">
            <RichText html={form.successText} />
          </div>
        ) : (
          <div>
            <h2>Takk for din henvendelse!</h2>
          </div>
        )}
      </div>
    );
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        _form.submit();
      }}
    >
      {visibleFields.map((field) => {
        return (
          <FormField
            key={field._id}
            field={field}
            form={_form}
            query={store.context.query}
          />
        );
      })}
      {(_form.errored || _form.error) && (
        <div className="form-field">
          <p className="error">
            Noe gikk galt under innsending av skjema. Vennligst prøv på nytt
            eller kontakt oss direkte på telefon eller e-post
          </p>
        </div>
      )}
      <div className="form-field">
        <button className="secondary" type="submit" disabled={_form.submitting}>
          {form.submitButtonText || "Send inn"} {arrow}
        </button>
      </div>
    </form>
  );
};

interface FormdFieldProps {
  field: IFormField;
  form: UseInputInterface;
  query: Record<string, any>;
}

const FormField: React.FC<FormdFieldProps> = ({ field, form, query }) => {
  let formElement = null;
  const disabled = form.submitting || field.disabled;
  const fieldValue = query.hasOwnProperty(field.name)
    ? query[field.name]
    : field.defaultValue || "";

  switch (field.type) {
    case "text":
      formElement = (
        <label className="b3">
          {field.label} {field.required && "*"}
          <textarea
            className="b1"
            defaultValue={fieldValue}
            onChange={(e) => form.setField(field.name, e.target.value)}
            disabled={disabled}
            placeholder={field.placeholder}
            required={field.required}
            autoComplete={field.autocomplete}
          />
        </label>
      );
      break;
    case "select":
      formElement = (
        <label className="b3">
          {field.label} {field.required && "*"}
          <select
            className="b1"
            defaultValue={fieldValue}
            onChange={(e) => form.setField(field.name, e.target.value)}
            disabled={disabled}
            required={field.required}
            autoComplete={field.autocomplete}
          >
            <option value="">Vennligst Velg</option>
            {field.options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </label>
      );
      break;
    case "checkbox":
      const value = form.input[field.name];
      const hasMultipleOptions = field.options.length > 1;

      formElement = (
        <fieldset>
          <legend className="b3">{field.label}</legend>
          {field.options.map((option) => {
            const checked = hasMultipleOptions
              ? (value || []).includes(option.value)
              : value === option.value;

            return (
              <label key={option._id} className="b1">
                <input
                  type="checkbox"
                  value={option.value}
                  checked={checked}
                  disabled={disabled}
                  required={
                    option.required || (!hasMultipleOptions && field.required)
                  }
                  onChange={(e) => {
                    if (hasMultipleOptions) {
                      if (checked) {
                        form.setField(
                          field.name,
                          (value || []).filter((v: any) => v !== option.value)
                        );
                      } else {
                        form.setField(field.name, [
                          ...(value || []),
                          option.value,
                        ]);
                      }
                    } else {
                      form.setField(
                        field.name,
                        e.target.checked ? option.value : undefined
                      );
                    }
                  }}
                />
                <span>
                  {option.required && "*"} {option.label}
                </span>
              </label>
            );
          })}
        </fieldset>
      );
      break;
    case "radio":
      formElement = (
        <fieldset>
          <legend className="b3">
            {field.label} {field.required && "*"}
          </legend>
          {field.options.map((option) => {
            return (
              <label key={option._id} className="b1">
                <input
                  type="radio"
                  name={field.name}
                  value={option.value}
                  disabled={disabled}
                  required={field.required}
                  onChange={(e) => form.setField(field.name, e.target.value)}
                />
                {option.label}
              </label>
            );
          })}
        </fieldset>
      );
      break;
    default:
      formElement = (
        <label className="b3">
          {field.label} {field.required && "*"}
          <input
            type={field.type}
            defaultValue={fieldValue}
            onChange={(e) => form.setField(field.name, e.target.value)}
            className="b1"
            disabled={disabled}
            placeholder={field.placeholder}
            required={field.required}
            minLength={field.minlength}
            maxLength={field.maxlength}
            min={field.min}
            max={field.max}
            pattern={field.pattern}
            autoComplete={field.autocomplete}
          />
        </label>
      );
  }

  if (!formElement) {
    return null;
  }

  return <div className="form-field">{formElement}</div>;
};
